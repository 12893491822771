import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';

export interface Page {
  label: string;
  value: any;
}

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
})
export class PagerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() maxSize: 7;
  @Input() totalItems: 0;
  @Input() itemsPerPage: 0;
  @Input() currentPage: 1;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  pages: Page[] = [];

  constructor() { }

  ngOnInit() {
    this.updatePageLinks();
  }

  ngOnChanges(changes: any) {
    this.updatePageLinks();
  }

  ngOnDestroy() {
  }

  /**
   * Go to the first page
   */
  first() {
    if (!this.isFirstPage()) {
      this.setCurrent(1);
    }
  }

  /**
   * Go to the previous page
   */
  previous() {
    if (!this.isFirstPage()) {
      this.setCurrent(this.getCurrent() - 1);
    }
  }

  /**
   * Go to the next page
   */
  next() {
    if (!this.isLastPage()) {
      this.setCurrent(this.getCurrent() + 1);
    }
  }

  /**
   * Go to the last page
   */
  last() {
    if (!this.isLastPage()) {
      this.setCurrent(this.getLastPage());
    }
  }

  /**
   * Returns true if current page is first page
   */
  isFirstPage(): boolean {
    return this.getCurrent() === 1;
  }

  /**
   * Returns true if current page is last page
   */
  isLastPage(): boolean {
    return this.getLastPage() === this.getCurrent();
  }

  /**
   * Set the current page number.
   */
  setCurrent(page: number) {
    this.pageChange.emit(page);
  }

  /**
   * Get the current page number.
   */
  getCurrent(): number {
    return this.currentPage;
  }

  /**
   * Returns the last page number
   */
  getLastPage(): number {
    if (this.totalItems < 1) {
      // when there are 0 or fewer (an error case) items, there are no "pages" as such,
      // but it makes sense to consider a single, empty page as the last page.
      return 1;
    }
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }

  /**
   * Updates the page links and checks that the current page is valid. Should run whenever the
   * PaginationService.change stream emits a value matching the current ID, or when any of the
   * input values changes.
   */
  private updatePageLinks() {
    const correctedCurrentPage = this.outOfBoundCorrection();

    if (correctedCurrentPage !== this.currentPage) {
      setTimeout(() => {
        this.setCurrent(correctedCurrentPage);
        this.pages = this.createPageArray(this.currentPage, this.itemsPerPage, this.totalItems, this.maxSize);
      });
    } else {
      this.pages = this.createPageArray(this.currentPage, this.itemsPerPage, this.totalItems, this.maxSize);
    }
  }

  /**
   * Checks that the instance.currentPage property is within bounds for the current page range.
   * If not, return a correct value for currentPage, or the current value if OK.
   */
  private outOfBoundCorrection(): number {
    const totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    if (totalPages < this.currentPage && 0 < totalPages) {
      return totalPages;
    } else if (this.currentPage < 1) {
      return 1;
    }

    return this.currentPage;
  }

  /**
   * Returns an array of Page objects to use in the pagination controls.
   */
  private createPageArray(currentPage: number, itemsPerPage: number, totalItems: number, paginationRange: number): Page[] {
    // paginationRange could be a string if passed from attribute, so cast to number.
    paginationRange = +paginationRange;
    const pages = [];
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const halfWay = Math.ceil(paginationRange / 2);

    const isStart = currentPage <= halfWay;
    const isEnd = totalPages - halfWay < currentPage;
    const isMiddle = !isStart && !isEnd;

    const ellipsesNeeded = paginationRange < totalPages;
    let i = 1;

    while (i <= totalPages && i <= paginationRange) {
      let label;
      const pageNumber = this.calculatePageNumber(i, currentPage, paginationRange, totalPages);
      const openingEllipsesNeeded = (i === 2 && (isMiddle || isEnd));
      const closingEllipsesNeeded = (i === paginationRange - 1 && (isMiddle || isStart));
      if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
        label = '...';
      } else {
        label = pageNumber;
      }
      pages.push({
        label: label,
        value: pageNumber
      });
      i ++;
    }
    return pages;
  }

  /**
   * Given the position in the sequence of pagination links [i],
   * figure out what page number corresponds to that position.
   */
  private calculatePageNumber(i: number, currentPage: number, paginationRange: number, totalPages: number) {
    const halfWay = Math.ceil(paginationRange / 2);
    if (i === paginationRange) {
      return totalPages;
    } else if (i === 1) {
      return i;
    } else if (paginationRange < totalPages) {
      if (totalPages - halfWay < currentPage) {
        return totalPages - paginationRange + i;
      } else if (halfWay < currentPage) {
        return currentPage - halfWay + i;
      } else {
        return i;
      }
    } else {
      return i;
    }
  }
}
