import {Component, OnDestroy, OnInit} from '@angular/core';
import {Logout} from '../modules/user/models/user.actions';
import {Logout as CompanyLogout} from '../modules/company/models/company.actions' ;

import {environment} from '../../environments/environment';
import {AuthenticatedComponent} from '../pages/authenticated/authenticated.component';
import * as Raven from 'raven-js';
import {User} from '@klippa/documentpipeline-api-js';

@Component({
  selector: 'app-dashboard',
  templateUrl: './common-layout.component.html'
})

export class CommonLayoutComponent extends AuthenticatedComponent implements OnInit, OnDestroy {

  public app: any;
  public headerThemes: any;
  public changeHeader: any;
  public sidenavThemes: any;
  public changeSidenav: any;
  public headerSelected: any;
  public sidenavSelected: any;
  public searchActived: any;
  public searchModel: any;
  public user: User;

  constructor() {
    super();
    this.app = {
      layout: {
        isMenuOpened: true,
        isMenuCollapsed: false,
        themeConfigOpen: false,
        rtlActived: false,
        searchActived: false
      }
    };

    this.headerThemes = ['header-default', 'header-primary', 'header-info', 'header-success', 'header-danger', 'header-dark'];
    this.changeHeader = changeHeader;

    function changeHeader(headerTheme) {
      this.headerSelected = headerTheme;
    }

    this.sidenavThemes = ['sidenav-default', 'side-nav-dark'];
    this.changeSidenav = changeSidenav;

    function changeSidenav(sidenavTheme) {
      this.sidenavSelected = sidenavTheme;
    }
  }


  ngOnInit() {
    super.ngOnInit();
  }

  logoutUser() {
    this.router.navigate(['/login']).then(() => {
      this.documentPipelineService.tokenStorage.setKey(null);
      this.documentPipelineService.tokenStorage.setSecret(null);
      this.documentPipelineService.tokenStorage.setRefreshToken(null);
      this.store.dispatch(new Logout());
      this.store.dispatch(new CompanyLogout());
      this.notificationService.success('Log out successful.');
      Raven.setUserContext();
    });
  }
}
