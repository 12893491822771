import { Action } from '@ngrx/store';

export enum TemplateBuilderActionTypes {
  SetPipelinesAction = '[SetPipelines] Action',
}
export class SetPipelines implements Action {
  readonly type = TemplateBuilderActionTypes.SetPipelinesAction;
  constructor(public payload: {pipelines: any}) {}
}

export type TemplateBuilderActions = SetPipelines;
