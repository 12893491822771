import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {_} from '../../util/i18n';
import {Login, UpdateKey} from '../../modules/user/models/user.actions';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent extends BaseComponent implements OnInit {
  loadingUser = false;

  constructor(protected route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    if (!this.route.snapshot.data || !this.route.snapshot.data.dontRedirectWhenLoggedIn) {
      this.store.select('user').take(1).subscribe(val => {
        this.loadingUser = true;

        // User already loaded, redirect.
        if (val.currentUser != null) {
          this.notificationService.info(this.getTranslation(_('You are already logged in. You have been redirected to the dashboard!')));
          this.router.navigate(['/templates']);
          return;
        }

        // User has key. Attempt login.
        if (this.documentPipelineService.tokenStorage.getKey() !== null && this.documentPipelineService.tokenStorage.getKey() !== '') {
          this.documentPipelineService.user.userMe().then((r) => {
            this.store.dispatch(new Login({user: r.data.Data}));
            this.notificationService.info(this.getTranslation(_('You are already logged in. You have been redirected to the dashboard!')));
            this.router.navigate(['/templates']);

          }).catch((r) => {
            // Error handling is handled by UserService.
            this.loadingUser = false;
          });
        } else {
          this.loadingUser = false;
        }
      });
    }
  }

}
