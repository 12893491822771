import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Company, User} from '@klippa/documentpipeline-api-js';

@Component({
  selector: 'app-authenticated',
  templateUrl: './authenticated.component.html'
})
export class AuthenticatedComponent extends BaseComponent implements OnInit {
  public user: User;
  public availableCompanies: Company[] = [];
  public selectedCompanies: Company[] = [];
  public availablePipelines: any;
  public currentPipelineID: number;

  constructor() {
    super();
  }

  ngOnInit() {
    const userStoreSubscription = this.store.select('user').subscribe(val => {
      this.user = val.currentUser;
    });

    const companyStoreSubscription = this.store.select('company').subscribe(val => {
      this.availableCompanies = val.availableCompanies;
      this.selectedCompanies = val.selectedCompanies;
    });

    const templateBuilderStoreSubscription = this.store.select('templateBuilder').subscribe(val => {
      this.availablePipelines = val.availablePipelines;
      this.currentPipelineID = this.availablePipelines[0].ID;
    });

    this.destroyCallbacks.push(() => {
      userStoreSubscription.unsubscribe();
      companyStoreSubscription.unsubscribe();
      templateBuilderStoreSubscription.unsubscribe();
    });
  }
}
