import { Action } from '@ngrx/store';
import {Company} from '@klippa/documentpipeline-api-js';

export enum CompanyActionTypes {
  SetCompaniesAction = '[SetCompanies] Action',
  SetActiveCompaniesAction = '[SetActivateCompanies] Action',
  LogoutAction = '[Logout] Action',
}
export class SetCompanies implements Action {
  readonly type = CompanyActionTypes.SetCompaniesAction;
  constructor(public payload: {companies: Company[]}) {}
}

export class SetActiveCompanies implements Action {
  readonly type = CompanyActionTypes.SetActiveCompaniesAction;
  constructor(public payload: {companies: Company[]}) {}
}

export class Logout implements Action {
  readonly type = CompanyActionTypes.LogoutAction;
}

export type CompanyActions = SetCompanies | SetActiveCompanies | Logout;
