import { Component, OnInit } from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {AuthenticationComponent} from '../authentication.component';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent extends AuthenticationComponent implements OnInit {
  loginForm: FormGroup;

  loading = false;
  submitted = false;

  constructor(protected route: ActivatedRoute, protected router: Router) {
    super(route);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(5)]]
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.documentPipelineService.user.userLogin( {Email: this.loginForm.controls.email.value, Password: this.loginForm.controls.password.value}).then((r) => {
      this.loading = false;
      this.finalizeLogin(r.data.Data);
    }).catch((e) => {
      this.loading = false;
      this.notifications.handleAPIError(e);
    });
  }

  // Helper for quick form control access.
  get f() {
    return this.loginForm.controls;
  }

}
