import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from "./pages/unauthenticated/login/login.component";
import {CurrentUserResolve} from "./modules/user/models/user.resolver.currentUser";
import {AvailableCompaniesResolve} from "./modules/company/models/company.resolver.availableCompanies";
import {CommonLayoutComponent} from "./layouts/common-layout.component";
import {AvailablePipelinesResolve} from "./modules/template/models/templatebuilder.resolver.availablePipelines";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: CommonLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './pages/authenticated/authenticated.module#AuthenticatedModule',
        resolve: {
          currentUser: CurrentUserResolve,
          availableCompanies: AvailableCompaniesResolve,
          availablePipelines: AvailablePipelinesResolve,
        }
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '**',
    redirectTo: '/login'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [CurrentUserResolve, AvailableCompaniesResolve, AvailablePipelinesResolve]
})
export class AppRoutingModule { }
