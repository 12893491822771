import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RestrictInputDirective } from './restrict-input.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [RestrictInputDirective],
  exports: [
    RestrictInputDirective
  ]
})
export class DirectivesModule { }
