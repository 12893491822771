import {Directive, ElementRef, Input} from '@angular/core';
import * as Inputmask from 'inputmask';


@Directive({
  selector: '[app-restrict-input]'
})
export class RestrictInputDirective {
  private regexMap = {
    integer: '^[0-9]*$',
    integer_negative: '^[+-]?[0-9]*$',
    float: '^[+-]?([0-9]*[.,])?[0-9]{2}$',
    words: '([A-z]*\\s)*',
  };

  constructor(private el: ElementRef) {

  }

  @Input('app-restrict-input')
  public set defineInputType(type: string) {
    Inputmask({regex: this.regexMap[type], placeholder: '', clearMaskOnLostFocus: false, showMaskOnHover: false, showMaskOnFocus: false, onBeforeMask: function (value, opts) {
        return value.toString();
      },
    } ).mask(this.el.nativeElement);
  }

}

