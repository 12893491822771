import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MomentModule} from 'ngx-moment';
import {NgSelectModule} from '@ng-select/ng-select';
import {PdfJsViewerModule} from 'ng2-pdfjs-viewer';
import {ImageViewerModule} from '@hallysonh/ngx-imageviewer';
import {TranslateModule} from '@ngx-translate/core';
import { LoaderComponent } from './loader/loader.component';
import {AutofocusDirective} from './auto-focus/auto-focus.directive';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {PagerComponent} from "./pager/pager.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    PdfJsViewerModule,
    ImageViewerModule,
    TranslateModule,
    MomentModule
  ],
  declarations: [
    LoaderComponent,
    AutofocusDirective,
    PagerComponent
  ],
  exports: [
    PagerComponent,
    LoaderComponent,
    AutofocusDirective,
  ],
  entryComponents: [
  ],
  providers: []
})
export class UiModule {
}
