import {TemplateBuilderActions, TemplateBuilderActionTypes} from './templatebuilder.actions';
import {Company} from '@klippa/documentpipeline-api-js';

export interface TemplateBuilderState {
  availablePipelines: Company[] | null;
}

export const initialTemplateBuilderState: TemplateBuilderState = {
  availablePipelines: null,
};

export function templateBuilderReducer(state = initialTemplateBuilderState,
                               action: TemplateBuilderActions): TemplateBuilderState {
  switch (action.type) {
    case TemplateBuilderActionTypes.SetPipelinesAction:
      return {
        ...state,
        availablePipelines: action.payload.pipelines
      };

    default:
      return state;
  }
}
