import { Injectable } from '@angular/core';
import {SnotifyService, SnotifyToastConfig} from 'ng-snotify';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private snotifyService: SnotifyService) { }

  notify(body: string, title: string = null, config: SnotifyToastConfig = null) {
    return this.snotifyService.simple(body, title, {showProgressBar: false, ...config});
  }

  success(body: string, title: string = null, config: SnotifyToastConfig = null) {
    return this.snotifyService.success(body, title, {showProgressBar: false, ...config});
  }

  info(body: string, title: string = null, config: SnotifyToastConfig = null) {
    return this.snotifyService.info(body, title, {showProgressBar: false,  ...config});
  }

  warning(body: string, title: string = null, config: SnotifyToastConfig = null) {
    return this.snotifyService.warning(body, title, {showProgressBar: false, ...config});
  }

  error(body: string, title: string = null, config: SnotifyToastConfig = null) {
    return this.snotifyService.error(body, title, {showProgressBar: false, ...config});
  }

  confirm(body: string, title: string = null, config: SnotifyToastConfig = null) {
    return this.snotifyService.confirm(body, title, {showProgressBar: false, ...config});
  }

  prompt(body: string, title: string = null, config: SnotifyToastConfig = null) {
    return this.snotifyService.prompt(body, title, {showProgressBar: false, ...config});
  }
}
