import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers/index';
import {NotificationService} from '../../../services/notification.service';
import 'rxjs/add/operator/take';
import {TranslateService} from '@ngx-translate/core';
import {SnotifyService} from 'ng-snotify';
import {DocumentPipelineService} from '@klippa/documentpipeline-api-angular';
import {Company} from '@klippa/documentpipeline-api-js';
import {SetCompanies} from './company.actions';

@Injectable()
export class AvailableCompaniesResolve implements Resolve<Company[]>, OnDestroy {

  constructor(private router: Router, private store: Store<AppState>, private documentPipelineService: DocumentPipelineService, private notificationService: NotificationService, private translate: TranslateService, private snotifyService: SnotifyService) {
  }

  ngOnDestroy() {}

  resolve(route: ActivatedRouteSnapshot) {
    return new Promise<Company[]>((res, rej) => {
      return this.documentPipelineService.companies.companiesListInput().then((r) => {
        this.store.dispatch(new SetCompanies({companies: r.data.Data.Companies}));
        return res(r.data.Data.Companies);
      }).catch((r) => {
        return res(null);
      });
    });
  }
}
