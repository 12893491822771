import { BrowserModule } from '@angular/platform-browser';
import {Injector, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BaseComponent} from "./base/base.component";
import {LoginComponent} from "./pages/unauthenticated/login/login.component";
import {AuthenticationComponent} from "./pages/unauthenticated/authentication.component";
import {DocumentPipelineModule} from "@klippa/documentpipeline-api-angular";
import {environment} from "../environments/environment";
import {CommonLayoutComponent} from "./layouts/common-layout.component";
import {AuthenticatedComponent} from "./pages/authenticated/authenticated.component";
import {LocalStorageModule} from "angular-2-local-storage";
import {SnotifyModule, SnotifyService, ToastDefaults} from "ng-snotify";
import {StoreModule} from "@ngrx/store";
import {MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateParser} from "@ngx-translate/core";
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CustomHandler, CustomParser} from "./util/i18n";
import {SortablejsModule} from "angular-sortablejs";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {reducers} from "./reducers";
import {HttpClient, HttpClientModule} from '@angular/common/http';
import * as fromUser from './modules/user/models/user.reducer';
import * as fromCompany from './modules/company/models/company.reducer';
import * as fromTemplateBuilder from './modules/template/models/templatebuilder.reducer';
import {UiModule} from "./shared/ui/ui.module";
import {DirectivesModule} from "./directives/directives.module";


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BaseComponent,
    AuthenticationComponent,
    CommonLayoutComponent,
    AuthenticatedComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      parser: {provide: TranslateParser, useClass: CustomParser},
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: CustomHandler},
    }),
    LocalStorageModule.forRoot({
      prefix: 'ocr',
      storageType: 'localStorage'
    }),
    AppRoutingModule,
    ReactiveFormsModule,
    SortablejsModule.forRoot({animation: 150}),
    StoreModule.forRoot(reducers),
    StoreModule.forFeature('user', fromUser.userReducer),
    StoreModule.forFeature('company', fromCompany.companyReducer),
    StoreModule.forFeature('templateBuilder', fromTemplateBuilder.templateBuilderReducer),
    FormsModule,
    UiModule,
    SnotifyModule,
    NgSelectModule,
    DirectivesModule,
    NgbModule,
    DocumentPipelineModule.forRoot({
      basePath: environment.api_url
    }),
  ],
  providers: [
    {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService,
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    BaseComponent.injector = this.injector;
  }
}
