import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers/index';
import 'rxjs/add/operator/take';
import {DocumentPipelineService} from '@klippa/documentpipeline-api-angular';
import {SetPipelines} from "./templatebuilder.actions";

@Injectable()
export class AvailablePipelinesResolve {

  constructor(private router: Router, private store: Store<AppState>, private documentPipelineService: DocumentPipelineService) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    return new Promise<any>((res, rej) => {
      return this.documentPipelineService.transformTemplateBuilder.transformTemplateBuilderPipelineList().then(r => {
        this.store.dispatch(new SetPipelines({pipelines: r.data.Data.Pipelines}));
        return res(r.data.Data.Pipelines);
      }).catch((r) => {
        return res(null);
      })
    })
  }
}
