import {CompanyActions, CompanyActionTypes} from './company.actions';
import {Company} from '@klippa/documentpipeline-api-js';

export interface CompanyState {
  availableCompanies: Company[] | null;
  selectedCompanies: Company[] | null;
}

export const initialCompanyState: CompanyState = {
  availableCompanies: null,
  selectedCompanies: null,
};

export function companyReducer(state = initialCompanyState,
                               action: CompanyActions): CompanyState {
  switch (action.type) {
    case CompanyActionTypes.SetCompaniesAction:
      return {
        ...state,
        availableCompanies: action.payload.companies
      };

    case CompanyActionTypes.SetActiveCompaniesAction:
      return {
        ...state,
        selectedCompanies: action.payload.companies,
      };

    case CompanyActionTypes.LogoutAction:
      return initialCompanyState;

    default:
      return state;
  }
}
