import {UserActions, UserActionTypes} from './user.actions';
import {User} from '@klippa/documentpipeline-api-js';

export interface UserState {
  currentUser: User | null;
  currentKey: string | null;
  currentSecret: string | null;
}

export const initialUserState: UserState = {
  currentUser: null,
  currentKey: null,
  currentSecret: null,
};

export function userReducer(state = initialUserState,
                            action: UserActions): UserState {
  switch (action.type) {
    case UserActionTypes.LoginAction:
      return {
        ...state,
        currentUser: action.payload.user
      };

    case UserActionTypes.UpdateAction:
      return {
        ...state,
        currentUser: action.payload.user
      };

    case UserActionTypes.UpdateKey:
      return {
        ...state,
        currentKey: action.payload.key,
        currentSecret: action.payload.secret,
      };

    case UserActionTypes.LogoutAction:
      return initialUserState;

    default:
      return state;
  }
}
